import Link from 'next/link';
import React, { useEffect, useState } from 'react';
// import Image from 'next/Image';
// import SVG from 'react-inlinesvg';
import { Col, Container, Navbar, Row } from 'react-bootstrap';
import LazyLoad from 'react-lazyload';
import { useFranchiseeContext } from '../../context/Franchisee';
import AddressString from '../AddressString';
// import DeleteFranchiseeButton from '../DeleteFranchiseeButton';
import CookieConsent from 'react-cookie-consent';
import PhoneLink from '../PhoneLink';
import { useRouter } from 'next/router';
import { faFacebookF, faGoogle } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MemoizedBookingStorePicker } from '../BookingStorePicker';
import Image from 'next/image';

interface FooterProps {
  className?: string;
}

const Footer: React.FC<FooterProps> = ({ className }) => {
  const {
    addressLineOne,
    addressLineTwo,
    city,
    state,
    zipCode,
    name,
    careerOpportunities,
    reviewFacebookUrl,
    reviewGooglePlusUrl,
    id: franchiseeId,
    slug: franchiseeSlug,
  } = useFranchiseeContext()?.franchisee;

  const [showCookie, setShowCookie] = useState(true);
  const [showStorePicker, setShowStorePicker] = useState<boolean>(false);
  const router = useRouter();

  useEffect(() => {
    if (window?.location?.href?.includes('gratuity')) {
      setShowCookie(false);
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      const cookieConfirm = document.querySelector('#rcc-confirm-button');
      if (cookieConfirm !== null) {
        const triggerClick = (e) => {
          e.click();
        };
        triggerClick(cookieConfirm);
      }
    }, 15000);
  }, []);

  function formatPhoneNumber(phoneNumberString) {
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return match[1] + '.' + match[2] + '.' + match[3];
    }
    return null;
  }

  const { phonePrimary } = useFranchiseeContext()?.franchisee;

  const fullYear = new Date().getFullYear();

  const funnelPages = ['/book', '/schedule', '/confirm'];

  const closeStorePicker = (event) => {
    if (!event.target.closest('.modal-content')) {
      setShowStorePicker(false);
    }
  };

  useEffect(() => {
    // handle closing the drawer if they click outside of it.
    document.addEventListener('click', closeStorePicker);

    return () => {
      // unmounts the event listener when the component is unmounted;
      document.removeEventListener('click', closeStorePicker);
    };
  }, []);

  return (
    <footer>
      <div className={`let-life-shine-section ${className}`}>
        <div className='let-life-shine-text'>
          <Image
            src='https://res.cloudinary.com/twomaidsengi/image/upload/c_scale,f_auto,q_auto,w_2000/v1670273202/hubsite-reskin/let-life-shine-pink.webp'
            // className='let-life-shine-text'
            layout='fill'
            sizes='(max-width: 768px) 200px, 300px'
            alt='Let Life Shine (Pink)'
          />
        </div>
      </div>
      <div className={`footer-nav-section bg-primary`}>
        <div className='footer-cta-section'>
          <img
            width='314px'
            height='68px'
            src='https://res.cloudinary.com/twomaidsengi/image/upload/c_scale,q_auto,w_314/v1670257150/hubsite-reskin/two-maids-logo-white.webp'
            className='mobile-footer-logo'
            alt='White Two Maids Logo'
          />
          <div className='footer-cta-section-buttons'>
            <Link href='/book'>
              <a>BOOK YOUR CLEANING</a>
            </Link>
            <Link href='/book'>
              <a>CALCULATE YOUR PRICE</a>
            </Link>
          </div>
          <div className='call-for-estimate-text'>
            <p>Call for a Free Estimate!</p>
            <PhoneLink number={phonePrimary} className='link-inverse' />
          </div>
        </div>
        <div>
          <div className='footer-circle'></div>
          <div className='footer-circle-two'></div>
        </div>
        <hr />
        <div className='footer-nav'>
          <div className='footer-address'>
            <img
              src='https://res.cloudinary.com/twomaidsengi/image/upload/c_scale,q_auto,w_314/v1670257150/hubsite-reskin/two-maids-logo-white.webp'
              className='footer-logo'
              alt='White Two Maids Logo'
              width='314px'
              height='68px'
            />
            <p>
              {addressLineOne} {addressLineTwo} <br />
              {city}, {state} {zipCode}
            </p>
            <p className='copyright-text'>
              &copy; {fullYear} Two Maids®. All rights reserved.
            </p>
          </div>
          <div>
            <div className='footer-links'>
              {router.pathname !== '/book' && (
                <>
                  {' '}
                  <Link href='/locations' passHref>
                    <a className='footer-link' aria-label={`Locations`}>
                      Locations
                    </a>
                  </Link>
                  <Link href='/services' passHref>
                    <a className='footer-link' aria-label={`Services`}>
                      Services
                    </a>
                  </Link>
                  <Link href='https://twomaidsfranchise.com' passHref>
                    <a
                      className='footer-link'
                      target='_blank'
                      aria-label={`Franchise Opportunities`}
                    >
                      Franchise Opportunities
                    </a>
                  </Link>
                  <Link href='/why-two-maids' passHref>
                    <a className='footer-link' aria-label={`Why Two Maids`}>
                      Why Two Maids
                    </a>
                  </Link>
                  <Link href='/careers' passHref>
                    <a className='footer-link' aria-label={`Careers`}>
                      Careers
                    </a>
                  </Link>
                  <Link
                    href={`${
                      franchiseeId > -1 ? `/${franchiseeSlug}/about` : '/about'
                    }`}
                    passHref
                  >
                    <a className='footer-link' aria-label={`About`}>
                      About
                    </a>
                  </Link>
                  <Link href='/faqs' passHref>
                    <a className='footer-link' aria-label={`FAQs`}>
                      FAQs
                    </a>
                  </Link>
                  <Link href='/reviews' passHref>
                    <a className='footer-link' aria-label={`Reviews`}>
                      Reviews
                    </a>
                  </Link>
                  <Link
                    href='https://twomaidsfranchise.com/cleaning-for-a-reason'
                    passHref
                  >
                    <a
                      className='footer-link'
                      target='_blank'
                      aria-label={`Cleaning for a Reason`}
                    >
                      Cleaning for a Reason
                    </a>
                  </Link>
                  <Link href='/terms-and-conditions' passHref>
                    <a
                      className='footer-link'
                      aria-label={`Terms and Conditions`}
                    >
                      Terms and Conditions
                    </a>
                  </Link>
                  <Link href='/privacy-policy' passHref>
                    <a className='footer-link' aria-label={`Privacy Policy`}>
                      Privacy Policy
                    </a>
                  </Link>
                  <Link
                    href='http://www.homefranchiseconcepts.com/wp-content/uploads/2023/03/CCPA-Privacy-Policy-for-California-Residents-Consumer-Version-FINAL.pdf'
                    passHref
                  >
                    <a
                      target='_blank'
                      className='footer-link'
                      aria-label={`Privacy Policy for CA Residents`}
                    >
                      Privacy Policy for CA Residents
                    </a>
                  </Link>
                  <Link href='/accessibility' passHref>
                    <a className='footer-link' aria-label={`Accessibility`}>
                      Accessibility
                    </a>
                  </Link>
                  <Link
                    href={
                      franchiseeId > -1 ? `/${franchiseeSlug}/contact/` : '/'
                    }
                  >
                    <a
                      onClick={(e) => {
                        if (franchiseeId === -1) {
                          e.preventDefault();
                          e.stopPropagation();
                          setShowStorePicker(!showStorePicker);
                        }
                      }}
                      className='footer-link'
                      aria-label={`Contact Us`}
                      style={{
                        color: router.pathname.match('/contact') ? '#000' : '',
                        display: funnelPages.includes(router.pathname)
                          ? 'none'
                          : 'block',
                      }}
                    >
                      Contact Us
                    </a>
                  </Link>
                </>
              )}
              <ul className='social-links-reskin-container'>
                {reviewFacebookUrl && !funnelPages.includes(router.pathname) && (
                  <li>
                    <a
                      className='header-link-reskin social-link-reskin'
                      aria-label='Visit Us on Facebook'
                      target='blank'
                      rel='noopener'
                      href={reviewFacebookUrl}
                    >
                      <FontAwesomeIcon icon={faFacebookF} size='lg' />
                    </a>
                  </li>
                )}
                {reviewGooglePlusUrl && !funnelPages.includes(router.pathname) && (
                  <li>
                    <a
                      className='header-link-reskin social-link-reskin'
                      aria-label='Review Us on Google'
                      target='blank'
                      rel='noopener'
                      href={reviewGooglePlusUrl}
                    >
                      <FontAwesomeIcon icon={faGoogle} size='lg' />
                    </a>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
      {showStorePicker && (
        <MemoizedBookingStorePicker
          showBookingModal={showStorePicker}
          handleShowStorePicker={(_, status) => setShowStorePicker(status)}
        />
      )}
    </footer>
  );
};

export default Footer;

// {showCookie && (
//   <CookieConsent
//     location='bottom'
//     buttonText='Continue to website'
//     cookieName='myAwesomeCookieName2'
//     style={{
//       background: '#fff',
//       display: 'block',
//       color: 'black',
//       width: '600px',
//       right: '20px',
//       borderRadius: '15px',
//       left: 'initial',
//       border: '1px solid #c4c4c4',
//       zIndex: '2324343',
//       marginBottom: '20px',
//     }}
//     buttonStyle={{
//       color: '#D4357F',
//       fontSize: '15px',
//       background: '#fff',
//       fontWeight: 'bolder',
//       padding: '0px',
//     }}
//     buttonWrapperClasses='cookie_disclaimer_button'
//     expires={150}
//     contentClasses='test-classes'
//     overlayClasses='test-overltay'
//   >
//     <p
//       style={{
//         fontSize: '22px',
//         fontWeight: 'bolder',
//         borderRadius: '15px',
//       }}
//       className='cookie_disclaimer_title'
//     >
//       This website uses cookies
//     </p>
//     <span>
//       {' '}
//       We use cookies to make sure this website can function. By
//       continuing to browse on this website, you agree to our use of
//       cookies.
//     </span>
//   </CookieConsent>
// )}
